<template>
  <div class="pa-2">

    <v-row class="pt-2">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          :class="tbody.length == 0? 'mr-3 grey' : 'mr-3 green'"
          icon
          v-bind="attrs"
          v-on="on"
          :disabled="tbody.length == 0"
          @click="exportCSV()"
          >
              <v-icon class="white--text">mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Export</span>
      </v-tooltip>
      <v-text-field
      dense
      outlined
      class="mr-3 shrink"
      placeholder="Search"
      v-model="searchTable"
      prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </v-row>
    
    <v-row>
      <v-col>
        <v-data-table
        :headers="thead"
        :items="tbody"
        :search="searchTable"
        :items-per-page="10"
        :loading="loadingTable"
        class="elevation-1 datatable-exceedance"
        :custom-sort="customSortTableExceedance"
        ></v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';
import Papa from "papaparse";

export default {

  data: () => ({

    listParams: [],
    defaultDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    defaultStationID: 'MPPS001',

    loadingTable: false,
    searchTable: "",
    thead: [
      {
        text: 'Station ID',
        // align: 'start',
        sortable: false,
        value: 'STATION_ID',
      },
      {
        text: 'Station',
        sortable: false,
        value: 'STATION_NAME',
      },
      {
        text: 'New ID',
        sortable: true,
        value: 'NEW_ID',
      },
      {
        text: 'Data Datetime',
        sortable: true,
        value: 'DATETIME_UI',
      },
      {
        text: 'Log Datetime',
        sortable: true,
        value: 'LOG_DT_UI',
      },
      {
        text: 'Parameter',
        sortable: true,
        value: 'PARAM_SELECTED',
      },
    ],
    tbody: [],
    
  }),

  mounted() {
    this.getParamList();
  },

  methods: {

    getParamList(){

      this.listParams = [];

      axios.get(this.globalUrl + "data/paramlist",
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;
            this.listParams = data;
      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    generateTable(date,station){

      this.loadingTable = true;

      // this.thead = [];
      this.tbody = [];

      axios.get(this.globalUrl + "report/tableexceedance?date="+date+"&stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;
            console.log(data);
            
            // Create THead Datatable ==================
            // let keys = Object.keys(data[0]);
            // let tempHeader = [];
            // for(let i in keys){
            //   for(let x in this.listParams){
            //     if(keys[i] == this.listParams[x].reading){
            //       tempHeader.push({
            //         text: this.listParams[x].unit? this.listParams[x].acronym + " (" + this.listParams[x].unit + ")" : this.listParams[x].acronym,
            //         sortable: true,
            //         value: keys[i],
            //       })
            //     }
            //   }
            // }

            // tempHeader.unshift({
            //   text: "Record Date",
            //   sortable: true,
            //   value: "RECEIVE_DT",
            // })

            // tempHeader.unshift({
            //   text: "Station ID",
            //   sortable: true,
            //   value: "STATION_ID",
            // })

            // tempHeader.unshift({
            //   text: "Date Time",
            //   sortable: true,
            //   value: "DATETIME",
            // })

            // this.thead = tempHeader


            // Create TBody Datatable ==================
            for(let i in data){
              data[i]["DATETIME_UI"] = this.displayUiDate(data[i]["DATETIME"]);
              data[i]["LOG_DT_UI"] = this.displayUiDate(data[i]["LOG_DT"]);
            }
            this.tbody = data;

            this.loadingTable = false;
            
      })
      .catch((error) => {
          console.log(error.response);
          this.loadingTable = false;
      });

    },

    customSortTableExceedance: function(items, index, isDesc) {
        // console.log(items, index, isDesc);

        items.sort((a, b) => {
            if (index[0]==='DATETIME_UI' || index[0]==='LOG_DT_UI') {
                if (!isDesc[0]) {
                  return Date.parse(this.displayDbDate(b[index])) - Date.parse(this.displayDbDate(a[index]))
                }
                else {
                  return Date.parse(this.displayDbDate(a[index])) - Date.parse(this.displayDbDate(b[index]))
                }
            }
            else {
                if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                }
            }
        });
        return items;
    },

    exportCSV(){
      let arrayHeader = []
      for(let i in this.thead){
        arrayHeader.push(this.thead[i].value)
      }

      let keys = arrayHeader,
      resultAfterFilter = this.tbody.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

      let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Exceedance Report Table.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
  },

  created (){
        bus.$on('triggerExceedanceContent', (data) => {

          // console.log(data);

          if (data.focus == "detailsFilter") {
            this.generateTable(data.info.date,data.info.stationID)
          }
          else if (data.focus == "clearAllDataReport") {
            // this.thead = [];
            // this.tbody = [];
            this.generateTable(this.defaultDate,this.defaultStationID);
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.datatable-exceedance > .v-data-table__wrapper > table > thead > tr > th{
  background: #91e4fb !important;
  white-space: nowrap !important;
}

.datatable-exceedance > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
.datatable-exceedance > .v-data-table__wrapper > table > tbody > tr > td:nth-child(3){
  white-space: nowrap !important;
}



</style>


