<template>
  <div>
    
    <v-row>
      <v-col>

        <v-btn
        v-for="item in listUnit"
        :key="item.params"
        class="mr-3"
        :color="modelBtnUnitSelected == item.params? 'primary' : 'secondary'"
        small
        @click="selectUnit(item)"
        >
          <v-icon dense class="mr-2">{{item.icon}}</v-icon>
          <span v-if="item.params != 'pH'">{{item.params}} ({{item.unit}})</span>
          <span v-else>{{item.params}}</span>
        </v-btn>

      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <highcharts
        :class="$vuetify.breakpoint.lgAndUp? 'highchartDaily' : ''"
        :options="highchartDaily"
        :constructorType="'stockChart'" 
        ref="refHighchartDaily"
        >
        </highcharts>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';

export default {

  data: () => ({

    defaultDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    defaultStationID: 'MPPS001',

    selectedDate: null,
    selectedStationID: null,

    listUnit: [
      { params: "Temperature", unit: "°C", icon: "mdi-thermometer-lines" },
      { params: "Salinity", unit: "ppt", icon: "mdi-water-opacity" },
      { params: "Turbidity", unit: "NTU", icon: "mdi-waves" },
      { params: "pH", unit: "", icon: "mdi-thermometer-probe" },
      { params: "DO", unit: "mg/L", icon: "mdi-chart-bubble" },
    ],
    modelBtnUnitSelected: "Temperature",

    highchartDaily: {
      chart: {
        // height: 500,
        // width: 'auto'
        // type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        plotLines: [],
      },
      series: [
        // {
        //   name: "Temperature",
        //   data: [],
        //   tooltip: {
        //     valueDecimals: 2
        //   }
        // }
      ],
      legend: {
          enabled: false
      },
    },

    // Note: Zone color plot axis base on ke bawah.. eg: value 31 ke bawah akan color #00b050
    zones: [
      {
        value: 31,
        color: '#00b050'
      },
      {
        value: 31.99,
        color: '#ffad10'
      },
      {
        // value: 100,
        color: '#ff0000'
      }
    ],

    plotLines: [
      {
        value: 31,
        color: '#ffad10',
        dashStyle: 'ShortDash',
      },
      {
        value: 31.99,
        color: '#ff0000',
        dashStyle: 'ShortDash',
      },
      // {
      //   // value: 100,
      //   color: '#ff0000',
      //   dashStyle: 'ShortDash',
      // }
    ],
    
  }),

  mounted() {

    if(localStorage.detailsFilterMPDCReport != undefined){
      let dataFilterLocalSt = JSON.parse(localStorage.getItem('detailsFilterMPDCReport'));
      this.generateChart(dataFilterLocalSt.date,dataFilterLocalSt.stationID);
    }
    else{
      this.generateChart(this.defaultDate,this.defaultStationID);
    }
    
  },

  methods: {
    
    selectUnit(unit){
      // console.log(unit);
      this.modelBtnUnitSelected = unit.params;
      this.generateChart(this.selectedDate,this.selectedStationID);
    },

    generateChart(date,station){

      console.log(date);
      console.log(station);

      this.highchartDaily.series = [];

      axios.get(this.globalUrl + "report/dailychart?date="+date+"&stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];

            let dataParamsSelected = [];
            for(let i in dataParams){
              if(this.modelBtnUnitSelected.toUpperCase() == dataParams[i].name){
                dataParamsSelected.push(dataParams[i]);
              }
            }

            
            let objSeries = {};
            if(this.modelBtnUnitSelected == "Temperature"){
              objSeries = {
                name: this.modelBtnUnitSelected,
                data: [],
                type: 'spline',
                zones: this.zones
              }

              this.highchartDaily.yAxis.plotLines = this.plotLines;
            }
            else{
              objSeries = {
                name: this.modelBtnUnitSelected,
                data: [],
                color: "#015781",
              }

              this.highchartDaily.yAxis.plotLines = [];
            }

            for(let i in dataSeries[0].data){
              for(let x in dataParamsSelected[0].data){
                if(i == x){
                  objSeries.data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                }
              }
            }

            this.highchartDaily.series.push(objSeries);

            // console.log(this.modelBtnUnitSelected);
      })
      .catch((error) => {
          console.log(error.response);
      });

    }

  },

  created (){
        bus.$on('triggerDailyContent', (data) => {

          console.log(data);

          if (data.focus == "detailsFilter") {
            this.generateChart(data.info.date,data.info.stationID);
            this.selectedDate = data.info.date;
            this.selectedStationID = data.info.stationID;
          }
          else if (data.focus == "clearAllDataReport") {
            // this.highchartDaily.series[0]
            this.modelBtnUnitSelected = "Temperature";
            this.generateChart(this.defaultDate,this.defaultStationID);
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


