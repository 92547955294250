<template>
  <div class="pa-2">
    
    <v-row class="pt-2">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          :class="tbody.length == 0? 'mr-3 grey' : 'mr-3 green'"
          icon
          v-bind="attrs"
          v-on="on"
          :disabled="tbody.length == 0"
          @click="exportCSV()"
          >
              <v-icon class="white--text">mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Export</span>
      </v-tooltip>
    </v-row>
    
    <v-row>
      <v-col>
        <v-data-table
        :headers="thead"
        :items="tbody"
        :hide-default-footer="true"
        :loading="loadingTable"
        class="elevation-1 statistic-monthly"
        ></v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';
import Papa from "papaparse";

export default {

  data: () => ({

    loadingTable: false,
    thead: [
      {
        text: 'Parameter',
        align: 'start',
        sortable: false,
        value: 'parameter',
      },
      {
        text: 'Temperature (°C)',
        align: 'start',
        sortable: false,
        value: 'ph',
      },
      {
        text: 'Salinity (ppt)',
        align: 'start',
        sortable: false,
        value: 'salinity',
      },
      {
        text: 'Turbidity (NTU)',
        align: 'start',
        sortable: false,
        value: 'turbidity',
      },
      {
        text: 'pH',
        align: 'start',
        sortable: false,
        value: 'ph',
      },
      {
        text: 'DO (mg/L)',
        align: 'start',
        sortable: false,
        value: 'docon',
      },
    ],
    tbody: [],
    
  }),

  mounted() {

    if(localStorage.detailsFilterMPDCReport != undefined){
      let dataFilterLocalSt = JSON.parse(localStorage.getItem('detailsFilterMPDCReport'));
      this.generateStats(dataFilterLocalSt.date,dataFilterLocalSt.stationID);
    }
    else{
      this.generateStats(this.defaultDate,this.defaultStationID);
    }
    
  },

  methods: {

    generateStats(date,station){

      this.loadingTable = true;
      this.tbody = [];

      axios.get(this.globalUrl + "report/monthstat?date="+date+"&stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;

            // Create TBody Datatable ==================
            let keys = Object.keys(data[0]);
            for(let i in data){
              for(let x in keys){
                if(keys[x] != "parameter"){
                  data[i][keys[x]] = data[i][keys[x]]? data[i][keys[x]].toFixed(2) : data[i][keys[x]];
                }
              }
            }

            this.tbody = data;

            this.loadingTable = false;
            
      })
      .catch((error) => {
          console.log(error.response);
          this.loadingTable = false;
      });

    },

    exportCSV(){

      let blob = new Blob([Papa.unparse(this.tbody)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Monthly Report Statistic.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    
  },

  created (){
        bus.$on('triggerMonthlyContent', (data) => {

          // console.log(data);

          if (data.focus == "detailsFilter") {
            this.generateStats(data.info.date,data.info.stationID)
          }
          else if (data.focus == "clearAllDataReport") {
            this.tbody = [];
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.statistic-monthly > .v-data-table__wrapper > table > thead > tr > th{
  background: #91e4fb !important;
  white-space: nowrap !important;
}

</style>


