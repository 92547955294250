<template>
  <div class="pa-5">
    
    <v-row>
      <v-col cols="3" class="py-1 pl-2">
        <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        v-model="modelPopupCalendar"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="modelDate"
            label="Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            dense
            outlined
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
          type="month"
          v-model="modelDate"
          @input="modelPopupCalendar = false;selectMonth()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-1 pl-1">
        <v-select
        :items="listLocation"
        label="Location"
        outlined
        dense
        hide-details="auto"
        v-model="modelLocation"
        prepend-inner-icon="mdi-map-marker-radius"
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-1 pl-1">
        <v-btn
        color="primary"
        @click="generateMonthlyReport"
        >
          Generate
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-tabs vertical class="my-tabs" v-model="tabSubParent">

          <v-tab
          v-for="items in listSubTab"
          :key="items.name"
          >
            <v-icon left>
              {{items.icon}}
            </v-icon>
            {{items.name}}
          </v-tab>



          <!-- Content Table -->
          <v-tab-item>
            <v-card flat>
              <!-- <v-card-text>
                
              </v-card-text> -->
              <mrtable></mrtable>
            </v-card>
          </v-tab-item>

          <!-- Content Chart -->
          <v-tab-item>
            <v-card flat>
              <mrchart></mrchart>
            </v-card>
          </v-tab-item>

          <!-- Content Stats -->
          <v-tab-item>
            <v-card flat>
              <mrstats></mrstats>
            </v-card>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';

import mrtable from '@/components/Report/MonthlyReport/ContentMonthlyReport/Table'
import mrchart from '@/components/Report/MonthlyReport/ContentMonthlyReport/Chart'
import mrstats from '@/components/Report/MonthlyReport/ContentMonthlyReport/Statistic'

export default {

  components: {
      'mrtable': mrtable,
      'mrchart': mrchart,
      'mrstats': mrstats,
  },

  data: () => ({

    // Form Input
    modelPopupCalendar: false,
    modelDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
    // modelDate: "January",
    modelLocation: 'Pulau Payar',
    listLocation: ['Pulau Payar'],

    tabSubParent: null,
    listSubTab: [
      { name: "Table", icon: "mdi-table" },
      { name: "Chart", icon: "mdi-chart-line" },
      { name: "Statistic", icon: "mdi-file-chart" },
    ]
    
  }),

  mounted() {
    this.getListStation();
  },

  methods: {

    selectMonth(){
      console.log(this.modelDate);
    },

    getListStation(){

      axios.get(this.globalUrl+'data/alldropdowns',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data["mpdc_station"];
            
            let temp = [];
            for(let i in data){
              temp.push(data[i].stationname)
            }

            this.listStations = data;
            this.listLocation = temp;


      })
      .catch((error) => {
          console.log(error.response);
      });

    },


    generateMonthlyReport(){

      let stationIdSelected = null;
      for(let i in this.listStations){
        if(this.listStations[i].stationname == this.modelLocation){
          stationIdSelected = this.listStations[i].stationid
        }
      }

      let detailsFilter = {
        date: this.modelDate,
        pulau: this.modelLocation,
        stationID: stationIdSelected,
      }

      localStorage.setItem('detailsFilterMPDCReport', JSON.stringify(detailsFilter));

      bus.$emit('triggerMonthlyContent', {focus: 'detailsFilter', info: detailsFilter});
    }
    
  },

  created (){
        bus.$on('triggerMonthlyContent', (data) => {

          // console.log(data);

          if (data.focus == "clearAllDataReport") {
            this.modelDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.modelLocation = 'Pulau Payar';
            this.tabSubParent = 0;
            this.thead = [];
            this.tbody = [];
          }

        })
  },


};
</script>

<style lang="scss">
@import '~scss/main';


</style>


