<template>
  <div class="pa-5">
    
    <v-row>
      <v-col cols="3" class="py-1 pl-2">
        <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        v-model="modelPopupCalendar"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="modelDate"
            label="Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            dense
            outlined
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
          v-model="modelDate"
          @input="modelPopupCalendar = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-1 pl-1">
        <v-select
        :items="listLocation"
        label="Location"
        outlined
        dense
        hide-details="auto"
        v-model="modelLocation"
        prepend-inner-icon="mdi-map-marker-radius"
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-1 pl-1">
        <v-btn
        color="primary"
        @click="generateExceedanceReport"
        >
          Generate
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ertable></ertable>
      </v-col>
    </v-row>
    
    <!-- <v-row>
      <v-col>
        <v-tabs vertical class="my-tabs" v-model="tabSubParent">

          <v-tab
          v-for="items in listSubTab"
          :key="items.name"
          >
            <v-icon left>
              {{items.icon}}
            </v-icon>
            {{items.name}}
          </v-tab>

          <v-tab-item>
            <v-card flat>
              <ertable></ertable>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <erchart></erchart>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <erstats></erstats>
            </v-card>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row> -->

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';

import ertable from '@/components/Report/ExceedanceReport/ContentExceedanceReport/Table'
// import erchart from '@/components/Report/ExceedanceReport/ContentExceedanceReport/Chart'
// import erstats from '@/components/Report/ExceedanceReport/ContentExceedanceReport/Statistic'

export default {

  components: {
      'ertable': ertable,
      // 'erchart': erchart,
      // 'erstats': erstats,
  },

  data: () => ({

    // Form Input
    modelPopupCalendar: false,
    modelDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modelLocation: 'Pulau Payar',
    listLocation: ['Pulau Payar'],

    tabSubParent: null,
    listSubTab: [
      { name: "Table", icon: "mdi-table" },
      { name: "Chart", icon: "mdi-chart-line" },
      { name: "Statistic", icon: "mdi-file-chart" },
    ]
    
  }),

  mounted() {
    this.getListStation();
  },

  methods: {
    
    getListStation(){

      axios.get(this.globalUrl+'data/alldropdowns',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data["mpdc_station"];
            
            let temp = [];
            for(let i in data){
              temp.push(data[i].stationname)
            }

            this.listStations = data;
            this.listLocation = temp;


      })
      .catch((error) => {
          console.log(error.response);
      });

    },


    generateExceedanceReport(){

      let stationIdSelected = null;
      for(let i in this.listStations){
        if(this.listStations[i].stationname == this.modelLocation){
          stationIdSelected = this.listStations[i].stationid
        }
      }

      let detailsFilter = {
        date: this.modelDate,
        pulau: this.modelLocation,
        stationID: stationIdSelected,
      }

      bus.$emit('triggerExceedanceContent', {focus: 'detailsFilter', info: detailsFilter});
    }

  },

  created (){
        bus.$on('triggerExceedanceContent', (data) => {

          // console.log(data);

          if (data.focus == "clearAllDataReport") {
            this.modelDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.modelLocation = 'Pulau Payar';
            this.tabSubParent = 0;
            this.thead = [];
            this.tbody = [];
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


