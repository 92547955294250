<template>
  <div>
    
    <v-row>
        <v-col cols="12">

            <v-card tile class="card-report-main-bg" elevation="0">
                <v-tabs
                v-model="tabParent"
                >
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab
                    v-for="item in listTabParent"
                    :key="item.name"
                    @click="selectMainTabReport(item)"
                    >
                        <v-icon class="mr-2">{{ item.icon }}</v-icon>
                        {{ item.name }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabParent">

                    <!-- Daily Report -->
                    <v-tab-item>
                        <dailyReport></dailyReport>
                    </v-tab-item>


                    <!-- Montly Report -->
                    <v-tab-item>
                        <monthlyReport></monthlyReport>
                    </v-tab-item>


                    <!-- Exceedance Report -->
                    <v-tab-item>
                        <exceedanceReport></exceedanceReport>
                    </v-tab-item>

                </v-tabs-items>
            </v-card>

        </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';

import dailyReport from '@/components/Report/DailyReport/DailyReport'
import monthlyReport from '@/components/Report/MonthlyReport/MonthlyReport'
import exceedanceReport from '@/components/Report/ExceedanceReport/ExceedanceReport'

export default {

  components: {
      'dailyReport': dailyReport,
      'monthlyReport': monthlyReport,
      'exceedanceReport': exceedanceReport,
  },

  data: () => ({

    tabParent: null,
    listTabParent: [
        {name: "Daily Report", icon: "mdi-calendar"},
        {name: "Monthly Report", icon: "mdi-calendar-month"},
        {name: "Exceedance Report", icon: "mdi-calendar-remove"},
    ],

    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    
  }),

  mounted() {
    
  },

  methods: {

      selectMainTabReport(dataTab){

        let detailsFilter = {
            date: this.today,
            pulau: "Pulau Payar",
            stationID: "MPPS001",
        }

        localStorage.setItem('detailsFilterMPDCReport', JSON.stringify(detailsFilter));

        bus.$emit('triggerDailyContent', {focus: 'clearAllDataReport', info: null});
        bus.$emit('triggerMonthlyContent', {focus: 'clearAllDataReport', info: null});
        bus.$emit('triggerExceedanceContent', {focus: 'clearAllDataReport', info: null});

      }
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.card-report-main-bg{
    height: calc(100vh - 76px);
}

</style>


